<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Calendar from 'primevue/calendar';

export default {
  locales: {
    en: {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    pt: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
    },
    es: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
    },
  },
  components: {
    Layout,
    VclList,
    Calendar
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      show: false,

      balance: 'R$ ***,**',

      loading: true,
      errored: false,
      total: 0,
      fields: [
        { key: "date", label: "Data" },
        { key: "description", label: "Descrição" },
        { key: "value", label: "Valor" },
      ],
      items: null,

      sortBy: "name",
      sortDesc: false,
      currentPage: "1",
      perPage: "25",
      periodMonth: new Date(),

      br: {
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      }
    };
  },
  methods: {
    getSaldo() {
      api.get("extrato/saldo").then((response) => {
        if (response.data.status == "success") {
          this.balance = this.$options.filters.currency(response.data.balance);
        }
      });
    },
    getPeriod() {
      if (this.periodMonth) {
        const date = [
          new Date(this.periodMonth).getFullYear(),
          new Date(this.periodMonth).getMonth() + 1
        ];

        return date.join('-');
      } else {
        const date = [
          new Date().getFullYear(),
          new Date().getMonth() + 1
        ];

        return date.join('-');
      }
    },
    getExtrato() {
      this.loading = true;
      this.errored = false;
      this.items = null;

      api
        .get("extrato?period=" + this.getPeriod())
        .then((response) => {
          if (response.data.status == "success") {
            this.total = response.data.total;
            this.items = response.data.list;
          }
        })
        .catch((error) => {
          this.errored = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getSaldo();
    this.getExtrato();
  },
  watch: {
    'periodMonth': function () {
      this.getExtrato();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="card mb-4">
      <div class="card-body">
        <h6>Dados Bancários</h6>
        <strong>{{ account.user.name }}</strong><br>
        <div v-if="account.user.bank && account.user.bank.status">
          Banco: {{ account.user.bank.numero_banco }} –
          Agência: {{ account.user.bank.agencia }} –
          Conta: {{ account.user.bank.conta }}-{{ account.user.bank.conta_digito }}
        </div>
      </div>
    </div>

    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Extrato</h3>
        <p>Consulte suas transações por período.</p>
      </div>
      <div class="page-title-right">
        <div class="card border-0 bg-soft-default mb-0">
          <div class="card-body py-0 d-flex align-items-center">
            <div class="font-size-15 py-3">Saldo disponível:</div>
            <h4 v-if="show" class="flex-fill m-0 ml-2 py-3">{{ this.balance }}</h4>
            <h4 v-else class="flex-fill m-0 ml-2 py-3">R$ ***,**</h4>
            <button v-if="show" class="border-0 rounded-circle ml-2 p-2 bg-white" v-on:click="show = false">
              <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 12a3.5 3.5 0 1 1 7.001.001A3.5 3.5 0 0 1 8.5 12Zm2 0a1.501 1.501 0 0 0 3 0 1.501 1.501 0 0 0-3 0Z" fill="currentColor"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2 12s2.955-7 10-7 10 7 10 7-2.955 7-10 7-10-7-10-7Zm10 5c2.304 0 4.354-.933 6.093-2.771A12.146 12.146 0 0 0 19.75 12a12.145 12.145 0 0 0-1.657-2.229C16.353 7.932 14.303 7 12 7c-4.369 0-6.843 3.418-7.753 4.995.338.583.888 1.416 1.66 2.234C7.647 16.067 9.697 17 12 17Z"
                  fill="currentColor"></path>
              </svg>
            </button>
            <button v-else class="border-0 rounded-circle ml-2 p-2 bg-white" v-on:click="show = true">
              <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="m3.78 19.5-1.414-1.42 2.338-2.35C2.844 13.86 2 11.852 2 11.852s2.955-7.034 10-7.034c1.13 0 2.156.182 3.08.486L18.367 2l1.414 1.422-16 16.078Zm.48-7.652c.338.586.888 1.425 1.663 2.25l.126.128.076.077 2.378-2.39v-.03l-.001-.03a3.51 3.51 0 0 1 3.5-3.518h.03l.03.001 1.373-1.38a8.04 8.04 0 0 0-1.433-.128c-4.364 0-6.836 3.436-7.742 5.02Z"
                  fill="currentColor"></path>
                <path
                  d="m18.667 7.384-1.422 1.43c.286.243.565.507.835.795.772.821 1.322 1.66 1.66 2.245a12.28 12.28 0 0 1-1.66 2.244c-1.737 1.846-3.782 2.78-6.08 2.78a7.82 7.82 0 0 1-2.41-.373l-1.55 1.56a9.71 9.71 0 0 0 3.96.825c7.045 0 10-7.035 10-7.035s-1.033-2.461-3.333-4.471Z"
                  fill="currentColor"></path>
                <path d="M12.002 15.37a3.51 3.51 0 0 0 3.5-3.518c0-.387-.064-.76-.179-1.108L10.9 15.19c.347.116.718.18 1.103.18Z" fill="currentColor"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="dropdown" class="d-flex mb-4">
      <b-dropdown variant="outline-light">
        <template #button-content>
          Filtrar período
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-group>
          <Calendar v-model="periodMonth" view="month" dateFormat="yy-mm-dd" inline :locale="br" style="width: 250px;">
          </Calendar>
        </b-dropdown-group>
      </b-dropdown>
      <b-dropdown variant="outline-light">
        <template #button-content>
          Itens por página
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form><b-form-radio v-model="perPage" value="10">10 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="25">25 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="50">50 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="100">100 itens</b-form-radio></b-dropdown-form>
      </b-dropdown>
    </div>

    <div id="extrato" class="card">
      <div class="card-body">
        <div class="error text-center" v-if="errored">
          Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.
        </div>
        <vcl-list v-else-if="loading" class="col-md-6"></vcl-list>
        <div v-else-if="items && items.length == 0" class="empty text-center">
          <strong>Nenhuma movimentação no período.</strong><br />
          Ao depositar um valor na sua conta ou realizar um pagamento, os lançamentos aparecerão aqui.
        </div>
        <div v-else class="table-responsive">
          <b-table :items="items" :fields="fields" class="table-nowrap table-style table-hover min-vh-50" head-variant="light" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :current-page="currentPage" :per-page="perPage" responsive>
            <template #cell(value)="row">
              {{ row.item.value | currency }}
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage" pills align="center"></b-pagination>
  </Layout>
</template>
